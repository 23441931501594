<template>
  <div class="gl-page-header" :class="(!$slots.left && !$slots.default) ? 'gl-page-header_right':''" style="min-width: 800px;">
    <div class="left" v-if="$slots.left">
      <slot name="left"/>
    </div>
    <div class="default-content" v-if="$slots.default">
      <slot />
    </div>
    <div class="right" v-if="$slots.right">
      <slot name="right"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlPageHeader',

}
</script>

<style lang="less" scoped>

.gl-page-header{
  // border-radius: 4px;
  // background-color: #fff;
  // padding: 16px;

  margin: 18px 18px 0;
  display: flex;
  justify-content: space-between;

  padding: 10px;
  background-color: #fff;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  border-radius: 4px;

  // .default-content{
  //   width: 100%;
  // }

  &_right{
    justify-content: flex-end;
  }
  .left{
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
  .right{
    text-align: right;
  }
}
</style>

<style lang="scss">
.gl-page-header{
  .el-form .el-form-item .el-form-item__label {
    padding-right: 5px;
  }
}
</style>