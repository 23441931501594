<template>
  <div class="gl-flex" :style="`justify-content: ${styleObj[align] ? styleObj[align] : 'center'}`">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'GlFlex',
  props: {
    align: {
      type: String,
      default: 'center', //left center right
    }
  },
  data(){
    return{
      styleObj: {
        center: 'center',
        left: 'flex-start',
        right: 'flex-end',
        between: 'space-between'
      }
    }
  },
}
</script>

<style lang="less" scoped>
.gl-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="less">
.gl-flex{
  .el-button{
    padding: 7px 15px;
    font-size: 12px;
  }
}
</style>