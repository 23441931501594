<template>
  <!-- || fileList.length == limit -->
  <div class="upload-images" :class="{ 'upload-images--single': limit <= 1 }">
    <!-- list-type="picture-card" -->
    <!-- :action="API" -->
    <!-- :on-success="onSuccess" -->
    <el-upload
      class="upload-demo"
      list-type="picture-card"
      ref="upload"
      action="action"
      :multiple="limit > 1"
      :limit="limit"
      :http-request="httpRequest"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-change="handleChange"
      :file-list="fileList"
      :auto-upload="autoUpload"
      :on-success="onSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <i class="el-icon-plus"></i>
      <span class="limit-view"> {{ fileList.length }}/{{ limit }} </span>

      <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { upload } from '@/api/user'
export default {
  name: 'gl-upload-images',
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    oldList: {
      type: Array,
      default: () => [],
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    accept: {
      type: String,
      default: 'image/png,image/jpg,image/jpeg',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 9,
    },
    type: {
      type: Number,
      default: 1,
    },
    maxSize: {
      type: Number,
      default: 2, // 2 * 1024 * 1024
    },
  },
  data() {
    return {
      actionApi: process.env.VUE_APP_BASE_API + '/api/upload',
      API: 'https://www.tanoshi.cc/nft_backend/api/file/file/uploadObjectOSS',
      headers: {
        'X-Requested-Token': '3B4vHVpE=',
      },
      fileList: [],
      urlList: [],
      uploadFile: [],
      dialogVisible: false,
      dialogImageUrl: '',
    }
  },
  watch: {
    oldList: {
      handler: function (newVal) {
        // if(this.limit <= 1) return
        if (!Array.isArray(newVal) && !newVal.length) return
        this.urlList = [...newVal]
        this.fileList = this.urlList.map((item) => {
          return {
            status: 'success',
            url: item,
            name: 'done',
          }
        })
        this.uploadFile = [...this.fileList]
      },
      // deep: true,
      immediate: true,
    },
    // fileList: {
    //   handler: function(newVal){
    //     const uploadCard = document.getElementsByClassName('el-upload--picture-card')
    //     if(newVal && newVal.length >= this.limit){
    //       uploadCard[0].style.display = 'none'
    //     }else {
    //       uploadCard[0].style.display = 'inline-block'
    //     }
    //   }
    // },
    value: {
      handler: function (newVal) {
        if (!newVal) {
          Object.assign(this.$data, this.$options.data())
          // this.fileList = []
          // this.uploadFile = []
          // this.urlList = []
        }
      },
    },
  },
  methods: {
    // async upload() {
    //   return new Promise((resolve, reject) => {
    //     const uploadAction = (item, fileIndex) => {
    //       return new Promise(async (resolve, reject) => {
    //         if (item.status == 'success') {
    //           return resolve(item.url)
    //         }
    //         const form = new FormData()
    //         form.append('file', item.raw)
    //         form.append('type', this.type)
    //         try {
    //           const res = await upload(form)
    //           console.log(res.data, 'res.data')
    //           resolve(res.data)
    //         } catch (e) {
    //           const err = {
    //             fileIndex,
    //             ...e,
    //           }
    //           reject(err)
    //         }
    //       })
    //     }

    //     Promise.all(this.uploadFile.map((item) => uploadAction(item)))
    //       .then((res) => {
    //         console.log(res, 'resres')
    //         resolve(res)
    //       })
    //       .catch((err) => {
    //         const fileIndex = err.fileIndex
    //         this.$message.error('图片上传失败')
    //         reject(err)
    //       })
    //   })
    // },

    /**
     * 原方法无法实现逐步上传，所以进行修改
     * 使用for..of循环一次处理一个上传操作，而不是使用Promise.all()它一次运行所有操作。也可以使用Promise.allSettled()
     */
    async upload() {
      try {
        const results = []
        for (const item of this.uploadFile) {
          if (item.status == 'success') {
            results.push(item.url)
          } else {
            const form = new FormData()
            form.append('file', item.raw)
            form.append('type', this.type)
            try {
              const res = await upload(form)
              console.log(res.data, 'res.data')
              results.push(res.data)
            } catch (e) {
              throw { fileIndex, ...e }
            }
          }
        }
        console.log(results, 'results')
        return results
      } catch (err) {
        const fileIndex = err.fileIndex
        this.$message.error('图片上传失败')
        throw err
      }
    },
    async httpRequest(e) {
      // return this.$refs.upload.submit()
      const file = e.file
      const form = new FormData()
      form.append('file', file)
      form.append('type', this.type)
      upload(form)
        .then((res) => {
          if (res.code == 200) {
            this.urlList.push(res.data)
            const value = this.limit === 1 ? this.urlList[0] : this.urlList
            this.$emit('input', value)
            e.onSuccess(res)
          } else {
            e.onError(res)
          }
        })
        .catch((err) => {
          this.$message.error('图片上传失败')
        })
    },
    handleChange(file, fileList) {
      // if(file.status === 'ready') this.uploadFile.push(file)
      console.log(file, fileList, 'fileList')
      this.fileList = [...fileList]
      this.uploadFile = [...fileList]
      if (!this.autoUpload) {
        this.$emit('input', fileList)
      }
    },
    onSuccess(res, file, fileList) {
      this.$emit('success', res, file, fileList)
    },
    handleRemove(file, fileList) {
      console.log('remove:', fileList)
      this.fileList = fileList
      this.uploadFile = fileList
      if (!this.fileList.length) {
        this.$emit('input', null) // this.limit === 1 ? '':[]
      } else {
        if (this.autoUpload) {
          let images = this.fileList.map((item) =>
            item.response?.data ? item.response?.data : item.url
          )
          this.urlList = images
          this.$emit('input', images)
        }
      }
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传之前
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < this.maxSize
      if (!isLt2M) this.$message.error('上传图片大小不能超过 2MB!')
      return isLt2M
    },
  },
}
</script>
<style lang="scss">
.upload-images {
  &--single {
    width: 148px;
    height: 148px;
    overflow: hidden;

    // .el-upload--picture-card:last-child{
    //   display: none;
    // }
  }
}
</style>
<style lang="scss">
.el-upload--picture-card {
  position: relative;
  .limit-view {
    display: inline-block;
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: -10px;
    color: #aaa;
  }
}
</style>
