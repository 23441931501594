<template>
  <div>
    <!-- style="display:flex;" style="display: inline-block;" -->
    <ul class="el-upload-list el-upload-list--picture-card">
      <span >
        <!-- start：拖拽开始 end：拖拽结束  imageLists：需要展示图片的数组-->
        <draggable
          class="el-upload-list el-upload-list--picture-card"
          v-model="imageLists"
          @start="onStart"
          @end="onEnd"
        >

          <div
            class="el-upload-list__item is-success"
            v-for="(item, index) in imageLists"
            :key="item + index"
          >
            <li>
              <div class="content1">
                <img
                  :src="item.url"
                  class="el-upload-list__item is-success"
                />
                <span class="el-upload-list__item-actions">
                  <!-- 删除图片 -->
                  <span class="remove">
                    <i class="el-icon-delete" @click="delImg(index)"></i>
                  </span>
                  <!-- 放大图片 -->
                  <span class="remove">
                    <i class="el-icon-circle-plus-outline" @click="handlePictureCardPreview(item.url)"></i>
                  </span>
                </span>
              </div>
            </li>
          </div>
        </draggable>
      </span>
      <span style="display: inline-block; ">
        <el-upload
          v-show="!limit || (imageLists.length < limit)"
          ref="uploadRef"
          multiple
          action="action"
          list-type="picture-card"
          :http-request="httpRequest"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-success="handleUploadSuccess"
          :on-change="handleChange"
          :file-list="imageLists"
          :before-upload="beforeUpload"
          :show-file-list="false"
          :auto-upload="autoUpload"
        >
          <i class="el-icon-plus"></i>
          <span class="show-limit"> {{imageLists.length}}/{{limit}} </span>
        </el-upload>
      </span>
    </ul>
    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { upload } from '@/api/user'
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 9
    },
    oldList: { // 历史图片
      type: Array,
      default: () => []
    },
    accept: { //   accept="image/*" : 可上传所有图片格式
      type: String,
      default: 'image/png,image/jpg,image/jpeg,image/gif,image/x-png',
    },
    maxSize: {
      type: [String, Number],
      default: 2   
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      imageLists: [],
      drag: false,
      dialogImageUrl: "",
      dialogVisible: false,
      // resourcesUrl: process.env.VUE_APP_RESOURCES_URL,
      modelValue: [],
    }
  },
  watch: {
    oldList: {
      handler: function(newVal){
        if(!newVal) return false
        this.imageLists = newVal.map(item => {
          return{
            status: 'success',
            url: item,
            status2: 'done',
          }
        })
      },
      immediate: true,
    },
    imageLists: {
      handler: function(fileList){
        if(fileList.length){
          this.modelValue = fileList.map(item => item.response?.data || item.url)
          this.$emit('input', this.modelValue)
          this.$emit('change', this.modelValue)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async upload(){
      console.log('组件upload')
      const uploadAction = () => {
        // const promiseList = []
        // this.imageLists.forEach(item => {
        //   if(item.status == 'success'){ //区分展示已上传的旧图片
        //     const task = new Promise(resolve => resolve(item.url))
        //     promiseList.push(task)
        //   }else{
        //     const form = new FormData()
        //     form.append('file', item.raw)
        //     form.append('type', this.type)
        //     const task = upload(form).then(res => res.data)
        //     promiseList.push(task)
        //   }
        // })
        // return promiseList
        
        const createTask = (item) => {
          const form = new FormData()
          form.append('file', item.raw)
          form.append('type', this.type)
          const task = upload(form).then(res => res.data)
          return task
        }
        const promiseList = this.imageLists.map(item => {
          if(item.status == 'success'){
            const task = new Promise(resolve => resolve(item.url))
            return task
          }
          return createTask(item)
        })
        return promiseList
      }

      try{
        const res = await Promise.all(uploadAction())
        return res
      }catch(e){
        return Promise.reject(e)
      }
    },
    httpRequest(e){
      const file = e.file
      const form = new FormData()
      form.append('file', file)
      form.append('type', this.type)
      upload(form).then(res => {
        console.log('res:', res)
        if(res.code == 200){
          // this.modelValue.push(res.data)
          // this.$emit('input', this.modelValue)
          // this.$emit('change', this.modelValue)
          e.onSuccess(res)
        }else{
          e.onError(res)
        }
      }).catch(err => {
        this.$message.error('图片上传失败')
      })
    },
    handleChange(file, fileList){
      // console.log('file:', file)
      console.log('fileList:', fileList)
      this.imageLists = [...fileList]
    },
    beforeUpload(file) {
      const isRange = file.size < this.maxSize * 1024 * 1024
      if(!isRange){
        this.$message.error(`上传图片大小不能超过 ${this.maxSize}MB!`)
      }
      return isRange
    },
    handleUploadSuccess(response, file, fileList) {
      this.$emit('success', response, file, fileList)
    },
    handleRemove(file, fileList) {
      // console.log('file', file)
      // console.log('fileList', fileList)
    },
    delImg(index) {
      setTimeout(() => {
        this.imageLists.splice(index, 1)
        // this.modelValue = this.imageLists.map(item => item.url)
        // this.$emit('input', this.modelValue)
        // this.$emit('change', this.modelValue)
      },50)

      //方法二: 调用el-upload组件内置方法
      // const delData = this.imageLists.splice(index, 1)
      // console.log('delData:', delData[0])
      // this.$refs.uploadRef.handleRemove(delData[0], this.imageLists)
      // this.$refs.uploadRef.onChange(delData[0], this.imageLists)
    },
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url
      this.dialogVisible = true
    },
    //开始拖拽事件
    onStart(e) {},
    //拖拽结束事件
    onEnd(e) { // 这里将会有调整前后的 index 及其他可能需要传递给接口的信息
      console.log(e) 
    },
  },
}
</script>

<style lang="scss" scoped>
.show-limit{
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -10px;
  color: #aaa;
}
</style>