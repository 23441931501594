
import {login, logout,getMenuList } from '@/api/user'
import Cookies from '@/plugins/cookies.js'
import { flatten } from '@/utils'
import { SStorage } from '@/utils/storage'
import base64 from '../../utils/base64'


const MENU_LIST_KEY = 'global_menu_list'
const MENU_PATH_LIST_KEY = 'global_menu_path_list'
const TOKEN_KEY = 'access_token'
const USER_INFO_KEY = 'user_info'

const state = {
  accessToken: SStorage.get(TOKEN_KEY) || null,
  // accessToken: Cookies.token() || null,
  userInfo: SStorage.get(USER_INFO_KEY) || {},
  meunList: [],
  menuPathList: [],
  //总后台-会员管理-账号管理-账号类型
  accountType: [
    {name: '普通用户', value: '1'},
    {name: '大喜', value: '2'},
    {name: '小喜', value: '5'},
    {name: '厂家', value: '3'},
    {name: '内部账户', value: '4'},
  ],
}
const getters = {
  accessToken: state => state.accessToken,
  userInfo: state => state.userInfo,
  menuList: state => state.menuList,
  menuPathList: state => state.menuPathList,
}
const mutations = {
  setAccessToken(state, token){
    state.accessToken = token
  },
  setUserInfo(state, userInfo){
    state.userInfo = userInfo
  },
  setMenuList(state, menuList){
    state.menuPathList = flatten(menuList) //扁平菜单
    state.menuList = menuList
  },
  setMenuPathList(state, menuPathList){
    state.menuPathList = menuPathList
  }
}
const actions = {
  loadMenuList(context){
    return new Promise(async(resolve,reject) => {
      const menuList = SStorage.get(MENU_LIST_KEY) || []
      console.log('SStorage-menuList:', menuList)
      if(menuList && menuList.length){
        context.commit('setMenuList', menuList)
        resolve(context.state.menuPathList)
      }else{
        try{
          const res = await getMenuList()
          let menuData = res.data
          SStorage.remove(MENU_LIST_KEY)
          SStorage.set( MENU_LIST_KEY, menuData )
          context.commit('setMenuList', menuData)
          // let menuPathList = flatten(menuData)   //扁平菜单
          // context.commit('setMenuPathList', menuPathList)
          resolve(context.state.menuPathList)

        }catch(err){
          SStorage.set( MENU_LIST_KEY, [] )
          context.commit('setMenuList', [])
          resolve([])
        }
      }
    })
  },
  async login(context, accountInfo){
    const {account, password, validateCode} = accountInfo
    try{
      const loginForm = {
        account, 
        password: base64.base64Encode(password), 
        code: validateCode
      }
      const res = await login(loginForm)
      const accessToken = res.data.token
      Cookies.setToken(accessToken)
      SStorage.set(TOKEN_KEY, accessToken)
      context.commit('setAccessToken',accessToken)
      let userInfo = res.data
      context.commit('setUserInfo', userInfo)
      SStorage.set(USER_INFO_KEY, userInfo)
      return userInfo
    }catch(e){
      return Promise.reject(e)
    }
  },
  async logout(context, isRquest = false){
    try{
      var result = null
      if(isRquest){
        console.error('logout')
        const res = await logout()
        result = res.data
      }
      SStorage.clear()
      context.commit('setAccessToken', '')
      context.commit('setUserInfo', {})
      Cookies.removeToken()
      // router.replace({ path: '/login' })
      return isRquest ? result : true
    }catch(e){
      return Promise.reject(e)
    }
    
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}