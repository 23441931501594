
//固定菜单


// 酷喜菜单
const coolMenuList = [
  {
    parentId: "",
    id: "1",
    icon: 'el-icon-s-home',
    islast: true,
    level: 1,
    menuType: "MENU",
    name: '首页',
    path: "/main/cool/index",
    sort: 0,
  },
  {
    parentId: "",
    id: "3",
    icon: 'el-icon-s-order',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '订单管理',
    path: "/main/cool/order",
    sort: 2,
    children: [
      {
        parentId: "3",
        id: "3-1",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "订单列表",
        path: "/main/cool/order/list",
        sort: 1,
        children: []
      },
    ]
  },
  {
    parentId: "",
    id: "2",
    icon: 'el-icon-setting',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '权限管理',
    path: "/main/cool/account",
    sort: 1,
    children: [
      {
        parentId: "2",
        id: "2-1",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '账号管理',
        path: "/main/cool/account/management",
        sort: 0,
        children: []
      },
      {
        parentId: "2",
        id: "2-2",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '角色管理',
        path: "/main/cool/account/roles",
        sort: 1,
        children: []
      },
    ],
  },
  {
    parentId: "",
    id: "4",
    icon: "el-icon-s-goods",
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: "订货管理",
    path: "/main/cool/goodsManagement",
    sort: 3,
    children: [
      {
        parentId: "4",
        id: "4-1",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "商品",
        path: "/main/cool/goodsManagement/goods",
        sort: 1,
      },
      {
        parentId: "4",
        id: "4-2",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "订货订单",
        path: "/main/cool/goodsManagement/order",
        sort: 2,
      }
    ]
  },
  {
    parentId: "",
    id: "5",
    icon: "el-icon-s-finance", 
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: "财务管理",
    path: "/main/cool/finance",
    sort: 4,
    children: [
      {
        parentId: "5",
        id: "5-1",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "结算列表",
        path: "/main/cool/finance/orderList",
        sort: 1,
      },
      {
        parentId: "5",
        id: "5-2",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "结算提现",
        path: "/main/cool/finance/withdrawDeposit",
        sort: 2,
      }
    ]
  },
  //小喜管理
  {
    parentId: "",
    id: "6",
    icon: 'el-icon-user-solid',
    islast: true,
    level: 1,
    menuType: "MENU",
    name: '小喜管理',
    path: "/main/cool/userInfo",
    sort: 0,
  },
]




// 总后台菜单
const backendallMenuList = [
  {
    parentId: "",
    id: "all-1",
    icon: 'el-icon-s-home',
    islast: true,
    level: 1,
    menuType: "MENU",
    name: '首页',
    path: "/main/backendall/index",
    sort: 1,
  },
  {
    parentId: "",
    id: "all-3",
    icon: "el-icon-s-finance",
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: "财务管理",
    path: "/main/backendall/finance",
    sort: 3,
    children: [
      {
        parentId: "all-3",
        id: "3-1",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "提款管理",
        path: "/main/backendall/finance/drawings",
        sort: 1,
      },
      {
        parentId: "all-3",
        id: "3-2",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "订单查询",
        path: "/main/backendall/finance/orderQuery",
        sort: 2,
      },
      {
        parentId: "all-4",
        id: "3-4",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "订单管理",
        path: "/main/backendall/finance/orderManagement",
        sort: 3,
      },
      {
        parentId: "all-5",
        id: "3-5",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "开票管理",
        path: "/main/backendall/finance/invoice",
        sort: 4,
      },
      {
        parentId: "all-5",
        id: "3-6",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "订单结算",
        path: "/main/backendall/finance/orderCloseout",
        sort: 4,
      },
    ]
  },
  {
    parentId: "",
    id: "all-4",
    icon: 'el-icon-picture',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '会员管理',
    path: "/main/backendall/member",
    sort: 4,
    children: [
      {
        parentId: "all-4",
        id: "4-1",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "账号管理",
        path: "/main/backendall/member/accountManagement",
        sort: 1,
      },
      {
        parentId: "all-4",
        id: "4-2",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "会员管理",
        path: "/main/backendall/member/memberManagement",
        sort: 2,
      },
      {
        parentId: "all-4",
        id: "4-3",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "搭档管理",
        path: "/main/backendall/member/coolManagement",
        sort: 2,
      },
      {
        parentId: "all-4",
        id: "4-4",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "厂家管理",
        path: "/main/backendall/member/merchantManagement",
        sort: 3,
      },
      {
        parentId: "all-4",
        id: "4-5",
        icon: "",
        islast: true,
        level: 2,
        menuType: "MENU",
        name: "留资管理",
        path: "/main/backendall/member/userApply",
        sort: 3,
      },
    ],
  },
  {
    parentId: "",
    id: "all-5",
    icon: 'el-icon-setting',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '系统设置',
    path: "/main/backendall/systemSetup",
    sort: 5,
    children: [
      {
        parentId: "all-5",
        id: "all-5-1",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '协议文本管理',
        path: "/main/backendall/systemSetup/agreement",
        sort: 1,
      },
      {
        parentId: "all-5",
        id: "all-5-2",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '轮播图管理',
        path: "/main/backendall/systemSetup/banner",
        sort: 2,
      },
      {
        parentId: "all-5",
        id: "all-5-3",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '公告管理',
        path: "/main/backendall/systemSetup/announcement",
        sort: 0,
      },
    ]
  },
  {
    parentId: "",
    id: "all-6",
    icon: 'el-icon-s-grid',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '社区管理',
    path: "/main/backendall/community",
    sort: 6,
    children: [
      {
        parentId: "all-6",
        id: "all-6-1",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '帖子管理',
        path: "/main/backendall/community/post",
        sort: 1,
      },
      {
        parentId: "all-6",
        id: "all-6-2",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '品论管理',
        path: "/main/backendall/community/comment",
        sort: 2,
      },
    ]
  },
  {
    parentId: "",
    id: "all-7",
    icon: 'el-icon-s-custom',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '权限管理',
    path: "/main/backendall/authorized",
    sort: 7,
    children: [
      {
        parentId: "all-7",
        id: "all-7-1",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '账号管理',
        path: "/main/backendall/authorized/account",
        sort: 1,
      },
      {
        parentId: "all-7",
        id: "all-7-2",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '角色管理',
        path: "/main/backendall/authorized/roles",
        sort: 2,
      },
    ]
  },
  {
    parentId: "",
    id: "all-8",
    icon: 'el-icon-money',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '交易管理',
    path: "/main/backendall/transaction",
    sort: 8,
    children: [
      {
        parentId: "all-8",
        id: "all-8-1",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '订单管理',
        path: "/main/backendall/transaction/order",
        sort: 1,
      },
      {
        parentId: "all-8",
        id: "all-8-2",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '评价管理',
        path: "/main/backendall/transaction/evaluate",
        sort: 2,
      },
    ]
  },
  {
    parentId: "",
    id: "all-9",
    icon: 'el-icon-money',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '商品管理',
    path: "/main/backendall/goods",
    sort: 9,
    children: [
      {
        parentId: "all-9",
        id: "all-9-1",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '商品管理',
        path: "/main/backendall/goods/goods",
        sort: 1,
      },
      {
        parentId: "all-9",
        id: "all-9-2",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '分类管理',
        path: "/main/backendall/goods/category",
        sort: 2,
      },
      {
        parentId: "all-9",
        id: "all-9-3",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '产品编号',
        path: "/main/backendall/goods/product",
        sort: 3,
      },
      {
        parentId: "all-9",
        id: "all-9-4",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '抵用卷管理',
        path: "/main/backendall/goods/reel",
        sort: 4,
      },
    ]
  },

]


const merchantMenuList = [
  {
    parentId: "",
    id: "m-1",
    icon: 'el-icon-s-home',
    islast: true,
    level: 1,
    menuType: "MENU",
    name: '首页',
    path: "/main/merchant/index",
    sort: 1,
  },
  {
    parentId: "",
    id: "m-2",
    icon: 'el-icon-setting',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '权限管理',
    path: "/main/merchant/authorization",
    sort: 2,
    children: [
      {
        parentId: "m-2",
        id: "m-2-1",
        icon: '',
        islast: true,
        level: 1,
        menuType: "MENU",
        name: '账号管理',
        path: "/main/merchant/authorization/account",
        sort: 1,
      },
      {
        parentId: "m-2",
        id: "m-2-2",
        icon: '',
        islast: true,
        level: 1,
        menuType: "MENU",
        name: '角色管理',
        path: "/main/merchant/authorization/roles",
        sort: 1,
      },
    ]
  },
  {
    parentId: "",
    id: "m-3",
    icon: 'el-icon-money',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '商品管理',
    path: "/main/merchant/goods",
    sort: 3,
    children: [
      {
        parentId: "m-3",
        id: "m-3-1",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '添加商品',
        path: "/main/merchant/goods/addGoods",
        sort: 1,
      },
      {
        parentId: "m-3",
        id: "m-3-2",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '商品列表',
        path: "/main/merchant/goods/goodsList",
        sort: 2,
      },
    ],
  },
  {
    parentId: "",
    id: "m-4",
    icon: 'el-icon-s-order',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '订单管理',
    path: "/main/merchant/order",
    sort: 4,
    children: [
      {
        parentId: "m-4",
        id: "m-4-1",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '订单列表',
        path: "/main/merchant/order/orderList",
        sort: 1,
      },
      // {
      //   parentId: "m-4",
      //   id: "m-4-2",
      //   icon: '',
      //   islast: true,
      //   level: 2,
      //   menuType: "MENU",
      //   name: '发货',
      //   path: "/main/merchant/order/deliverGoods",
      //   sort: 2,
      // },
    ]
  },
  {
    parentId: "",
    id: "m-5",
    icon: 'el-icon-s-finance',
    islast: false,
    level: 1,
    menuType: "CATALOGUE",
    name: '财务管理',
    path: "/main/merchant/finance",
    sort: 5,
    children: [
      {
        parentId: "m-5",
        id: "m-5-1",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '结算列表',
        path: "/main/merchant/finance/orderList",
        sort: 1,
      },
      {
        parentId: "m-5",
        id: "m-5-2",
        icon: '',
        islast: true,
        level: 2,
        menuType: "MENU",
        name: '结算提现',
        path: "/main/merchant/finance/withdrawDeposit",
        sort: 1,
      },
    ]
  },

]



const menuList = [
  ...coolMenuList,
  ...backendallMenuList,
  ...merchantMenuList,
]


export default menuList