<template>
  <div class="gl-wrap" :style="style">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'GlWrap',
  props: {
    padding: {
      type: String,
      default: '20px'
    },
    right: {
      type: String,
      default: ''
    },
  },
  computed: {
    style(){
      if(this.right) return { 'padding-right': this.right }
      return {padding: this.padding}
    },
  },
}
</script>

<style lang="scss" scoped>

</style>