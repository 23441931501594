

//编码
export const enCode = (data) => {
  let str = JSON.stringify(data)
  let code = encodeURI(str)
  let base64 = window.btoa(code)
  return base64
}

//解码
export const deCode = (base64) => {
  let code = window.atob(base64)
  let str = decodeURI(code)
  let data = JSON.parse(str)
  return data
}



/**
 * 

//加密
export const enCode = (str) => {
  if (str===undefined || str === "" || str === null) {
      return str;
  }
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
      }));
}

//解密
export const deCode = (str) => {
  if (str===undefined || str === "" || str === null) {
      return str;
  }
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

 */





// base64加密
export const base64Encode = (val) => {
  return window.btoa(val)
}

// base64解密
export const base64Decode = (base64) => {
  window.atob(base64)
}

export default {
  enCode,
  deCode,
  base64Encode,
  base64Decode,
}