
export default {
  '/main/merchant/index': {
    path: '/main/merchant/index',
    component: () => import('@/views/merchant/home/index.vue'),
    meta: {title: '首页'}
  },
  //权限管理
  '/main/merchant/authorization/account': {
    path: '/main/merchant/authorization/account',
    component: () => import('@/views/merchant/authorization/account/list.vue'),
    meta: {title: '账号管理'},
  },
  '/main/merchant/authorization/roles': {
    path: '/main/merchant/authorization/roles',
    component: () => import('@/views/merchant/authorization/roles/list.vue'),
    meta: {title: '角色管理'},
  },

  //商品管理
  '/main/merchant/goods/addGoods': {
    path: '/main/merchant/goods/addGoods',
    component: () => import('@/views/merchant/goods/addGoods/list.vue'),
    meta: {title: '添加商品'}
  },
  // '/main/merchant/goods/goodsList': {
  //   path: '/main/merchant/goods/goodsList',
  //   component: () => import('@/views/merchant/goods/goodsList/list.vue'),
  //   meta: {title: '商品列表'},
  //   children: [], 
  // },
  '/main/merchant/goods/goodsList': {
    path: '/main/merchant/goods/goodsList',
    component: () => import('@/views/merchant/goods/goodsList/index.vue'),
    redirect: '/main/merchant/goods/goodsList/index',
    meta: {title: '商品列表'},
    children: [
      {
        path: 'index',
        component: () => import('@/views/merchant/goods/goodsList/list.vue'),
        meta: {title: '商品列表'}    
      },
      {
        path: 'update',
        component: () => import('@/views/merchant/goods/goodsList/update.vue'),
        meta: {title: '新增商品'}    
      },
      {
        path: 'add',
        component: () => import('@/views/merchant/goods/goodsList/add.vue'),
        meta: {title: '新增'}    
      },
      {
        path: 'detail',
        component: () => import('@/views/merchant/goods/goodsList/detail.vue'),
        meta: {title: '详情'}    
      },
    ], 
  },

  // 订单管理
  '/main/merchant/order/orderList': {
    path: '/main/merchant/order/orderList',
    // component: () => import('@/views/merchant/order/orderList/list.vue'),
    // meta: {title: '订单列表'},
    component: () => import('@/views/merchant/order/orderList/index.vue'),
    redirect: '/main/merchant/order/orderList/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/merchant/order/orderList/list.vue'),
        meta: {title: '订单列表'}
      },
      {
        path: 'detail',
        component: () => import('@/views/coolLike/order/list/detail.vue'),
        meta: {title: '订单详情'}
      }
    ]
  },

  //财务管理
  '/main/merchant/finance/orderList': {
    path: '/main/merchant/finance/orderList',
    component: () => import('@/views/merchant/finance/orderList/index.vue'),
    redirect: '/main/merchant/finance/orderList/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/merchant/finance/orderList/list.vue'),
        meta: {title: '结算列表'},
      },
      {
        path: 'detail',
        component: () => import('@/views/merchant/finance/orderList/detail.vue'),
      }
    ],
  },
  '/main/merchant/finance/withdrawDeposit': {
    path: '/main/merchant/finance/withdrawDeposit',
    component: () => import('@/views/merchant/finance/withdrawDeposit/list.vue'),
    meta: {title: '结算提现'}
  },

}