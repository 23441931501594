
export default {
  '/main/backendall/index': {
    path: '/main/backendall/index',
    component: () => import('@/views/backendall/home/index.vue'),
    meta: {title: '首页'}
  },

  //财务管理 
  '/main/backendall/finance/drawings': {
    path: '/main/backendall/finance/drawings',
    component: () => import('@/views/backendall/finance/drawings/list.vue'),
    meta: {title: '提款管理'}
  },
  '/main/backendall/finance/orderQuery': {
    path: '/main/backendall/finance/orderQuery',
    component: () => import('@/views/backendall/finance/orderQuery/index.vue'),
    redirect: '/main/backendall/finance/orderQuery/index',
    meta: {title: '订单查询'},
    children: [
      {
        path: 'index',
        component: () => import('@/views/backendall/finance/orderQuery/list.vue'),
        meta: {title: '订单查询'},
      },
      {
        path: 'detail',
        component: () => import('@/views/backendall/finance/orderQuery/detail.vue'),
        meta: {title: '详情'},
      },
    ],
  },
  '/main/backendall/finance/orderManagement': {
    path: '/main/backendall/finance/orderManagement',
    component: () => import('@/views/backendall/finance/orderManagement/list.vue'),
    meta: {title: '订单管理'}
  },
  '/main/backendall/finance/invoice': {
    path: '/main/backendall/finance/invoice',
    component: () => import('@/views/backendall/finance/invoice/list.vue'),
    meta: {title: '开票管理'}
  },
  '/main/backendall/finance/orderCloseout': {
    path: '/main/backendall/finance/orderCloseout',
    // component: () => import('@/views/backendall/finance/orderCloseout/list.vue'),
    component: () => import('@/views/backendall/finance/orderCloseout/index.vue'),
    meta: {title: '订单结算'},
    redirect: '/main/backendall/finance/orderCloseout/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/backendall/finance/orderCloseout/list.vue'),
        meta: {title: '订单结算'},
      },
      {
        path: 'detail',
        component: () => import('@/views/backendall/finance/orderCloseout/detail.vue'),
        meta: {title: '订单详情'},
      },
    ]
  },


  //会员管理
  '/main/backendall/member/accountManagement': {
    path: '/main/backendall/member/accountManagement',
    component: () => import('@/views/backendall/member/accountManagement/list.vue'),
    meta: {title: '账号管理'}
  },
  '/main/backendall/member/memberManagement': {
    path: '/main/backendall/member/memberManagement',
    component: () => import('@/views/backendall/member/memberManagement/list.vue'),
    meta: {title: '会员管理'}
  },
  '/main/backendall/member/coolManagement': {
    path: '/main/backendall/member/coolManagement',
    component: () => import('@/views/backendall/member/coolManagement/list.vue'),
    meta: {title: '酷喜管理'}
  },
  '/main/backendall/member/merchantManagement': {
    path: '/main/backendall/member/merchantManagement',
    component: () => import('@/views/backendall/member/merchantManagement/list.vue'),
    meta: {title: '厂家管理'}
  },
  '/main/backendall/member/userApply': {
    path: '/main/backendall/member/userApply',
    component: () => import('@/views/backendall/member/userApply/list.vue'),
    meta: {title: '留资管理'}
  },

  //系统设置
  '/main/backendall/systemSetup/agreement': {
    path: '/main/backendall/systemSetup/agreement',
    component: () => import('@/views/backendall/systemSetup/agreement/list.vue'),
    meta: {title: '协议文本管理'}
  },
  '/main/backendall/systemSetup/banner': {
    path: '/main/backendall/systemSetup/banner',
    component: () => import('@/views/backendall/systemSetup/banner/list.vue'),
    meta: {title: '轮播图管理'}
  },
  '/main/backendall/systemSetup/announcement': {
    path: '/main/backendall/systemSetup/announcement',
    component: () => import('@/views/backendall/systemSetup/announcement/list.vue'),
    meta: {title: '公告管理'}
  },

  //社区管理
  '/main/backendall/community/post': {
    path: '/main/backendall/community/post',
    component: () => import('@/views/backendall/community/post/list.vue'),
    meta: {title: '帖子管理'}
  },
  '/main/backendall/community/comment': {
    path: '/main/backendall/community/comment',
    component: () => import('@/views/backendall/community/comment/list.vue'),
    meta: {title: '评论管理'}
  },

  //权限管理
  '/main/backendall/authorized/account': {
    path: '/main/backendall/authorized/account',
    component: () => import('@/views/backendall/authorized/account/list.vue'),
    meta: {title: '账号管理'}
  },
  '/main/backendall/authorized/roles': {
    path: '/main/backendall/authorized/roles',
    component: () => import('@/views/backendall/authorized/roles/list.vue'),
    meta: {title: '角色管理'}
  },

  //交易管理
  '/main/backendall/transaction/order': {
    path: '/main/backendall/transaction/order',
    // component: () => import('@/views/backendall/transaction/order/list.vue'),
    // meta: {title: '订单管理'},
    component: () => import('@/views/coolLike/order/list/index.vue'),
    redirect: '/main/backendall/transaction/order/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/backendall/transaction/order/list.vue'),
        meta: {title: '订单列表'}
      },
      {
        path: 'detail',
        component: () => import('@/views/coolLike/order/list/detail.vue'),
        meta: {title: '订单详情'}
      }
    ]
  },
  '/main/backendall/transaction/evaluate': {
    path: '/main/backendall/transaction/evaluate',
    component: () => import('@/views/backendall/transaction/evaluate/list.vue'),
    meta: {title: '订单管理'}
  },




  //商品管理
  // '/main/backendall/goods/goods': {
  //   path: '/main/backendall/goods/goods',
  //   component: () => import('@/views/backendall/goods/goods/list.vue'),
  //   meta: {title: '商品管理'},
  // },
  '/main/backendall/goods/goods': {
    path: '/main/backendall/goods/goods',
    // component: () => import('@/views/backendall/goods/goods/list.vue'),
    component: () => import('@/views/backendall/goods/goods/index.vue'),
    redirect: '/main/backendall/goods/goods/index',
    meta: {title: '商品管理'},
    children: [
      {
        path: 'index',
        component: () => import('@/views/backendall/goods/goods/list.vue'),
        meta: {title: '商品列表',}    
      },
      {
        path: 'update',
        component: () => import('@/views/merchant/goods/goodsList/add.vue'),
        meta: {title: '修改',}    
      },
      {
        path: 'detail',
        component: () => import('@/views/merchant/goods/goodsList/detail.vue'),
        meta: {title: '详情',}    
      }
    ],
  },
  '/main/backendall/goods/category': {
    path: '/main/backendall/goods/category',
    component: () => import('@/views/backendall/goods/category/list.vue'),
    meta: {title: '分类管理'},
  },
  '/main/backendall/goods/product': {
    path: '/main/backendall/goods/product',
    component: () => import('@/views/backendall/goods/product/list.vue'),
    meta: {title: '产品编号'},
  },
  '/main/backendall/goods/reel': {
    path: '/main/backendall/goods/reel',
    component: () => import('@/views/backendall/goods/reel/list.vue'),
    meta: {title: '抵用卷管理'},
  },
  // vip会员管理
  '/main/backendall/vip/seting': {
    path: '/main/backendall/vip/seting',
    component: () => import('@/views/backendall/vip/seting/index.vue'),
    meta: {title: 'VIP设置'},
    redirect: '/main/backendall/vip/seting/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/backendall/vip/seting/list.vue'),
        meta: {title: 'VIP设置'},
      },
      {
        path: 'update',
        component: () => import('@/views/backendall/vip/seting/edit.vue'),
        meta: {title: '修改',}    
      },
    ],
  },
  '/main/backendall/vip/purchaseRecord': {
    path: '/main/backendall/vip/purchaseRecord',
    component: () => import('@/views/backendall/vip/purchaseRecord/list.vue'),
    meta: {title: 'VIP购买记录'},
  },
  '/main/backendall/vip/gift': {
    path: '/main/backendall/vip/gift',
    component: () => import('@/views/backendall/vip/gift/list.vue'),
    meta: {title: '会员礼品'},
  },
}