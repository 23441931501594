
import check from "./check.js"

const directives = {
  check,  //授权按钮指令
}

export default {
  install(Vue){
    Object.keys(directives).forEach(key => {
      Vue.directive(key, directives[key])
    })
  }
}

