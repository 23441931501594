<template>
  <div ref="editorRef" id="editor"></div>
</template>

<script>
import WangEditor from 'wangeditor'
import {upload} from '@/api/user'
export default {
  name: 'GLEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 300
    },
    uploadType: {
      type: Number,
      default: 1
    },
    maxSize: {
      type: Number,
      default: 5, // 2 * 1024 * 1024
    },
  },
  data(){
    return{
      editor: null,
      content: '',
      menuItem: [  //工具栏里有哪些工具
        'head',  // 标题
        'bold',  // 粗体
        'fontSize',  //字号
        'fontName',  //字体
        'italic',  // 斜体
        'underline',  //下划线
        'strikeThrough',  //删除线
        'indent',  //缩进
        'lineHeight',  //行高
        'foreColor',  //文字颜色
        'backColor',  //文字背景颜色
        'link',  //链接，插入一个链接地址，如果填写了描述，则高亮显示描述。若没有，则高亮显示链接
        'list',  // 序列（有序列表、无序列表）
        'todo',  //待办事项
        'justify',  // 对齐方式
        'quote',  //引用
        'emoticon',  //表情
        'image',  //插入图片
        // 'video',  //插入视频
        'table',  //表格
        'code',  //代码
        'splitLine',  //分割线
        'undo',  //撤销
        'redo' //恢复
      ],
      fontNames: [ /* 字体工具提供哪些字体? */
        "黑体",
        "仿宋",
        "楷体",
        "标楷体",
        "华文仿宋",
        "华文楷体",
        "宋体",
        "微软雅黑",
      ],
      colors: [  // 文字颜色、背景色可以选择哪些颜色?
        "#000000", "#eeece0", "#1c487f", "#4d80bf",
      ],
      colorsNameAndValue: {
        "#880000": "暗红色",
        "#800080": "紫色",
        "#ff0000": "红色"
      },
    }
  },
  watch: {
    value: {
      handler: function(newVal){
        if(newVal != this.content){
          // this.content = newVal
          this.setHtml(newVal)
          this.disabled && this.editor.disable() // 禁用;  启用: this.editor.enable()
        }
      }
    }
  },
  mounted(){
    const _this = this
    this.editor = new WangEditor(this.$refs.editorRef)
    // this.editor = new WangEditor('#editor')

    // 取消自动聚焦
    this.editor.config.focus = false
    // 配置层级
    this.editor.config.zIndex = 1
    // 设置编辑区域高度为 500px
    this.editor.config.height = this.height
    // 配置 onchange 回调函数，将数据同步到 vue 中
    this.editor.config.onchange = (newHtml) => {
      this.content = newHtml
      this.$emit('input', newHtml)
      this.$emit('change', newHtml)
    }
    // 忽略粘贴的图片
    this.editor.config.pasteIgnoreImg = true
    // 图片大小限制
    this.editor.config.uploadImgMaxSize = 5 * 1024 * 1024
    // 视频大小限制
    this.editor.config.uploadVideoMaxSize = 50 * 1024 * 1024
    // 视频类型限制
    this.editor.config.uploadVideoAccept = ['mp4']
     //设置菜单类型
    this.editor.mode = 'default' // default or 'simple'
    //设置菜单
    this.editor.config.menus = [...this.menuItem] /* 应用设置好的工具栏 */
    // 配置字体
    // this.editor.config.fontNames = [...this.fontNames]
    //配置文字演示
    // this.editor.config.colors = [...this.colors]

    // 自定义提示
    this.editor.config.customAlert = function (info) {
      _this.$message.error(info)
    }
    // 配置菜单栏，设置不需要的菜单
    // this.editor.config.excludeMenus = ['strikeThrough']
    //自定义参数
    // this.editor.config.uploadParams = {}

    //自定义请求头
    // this.editor.config.uploadImgHeaders = {}
    
    // 配置cos
    // this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
    //   resultFiles.map(async (item) => {
    //     try{
    //       const forms = new FormData()
    //       forms.append('file', item)
    //       forms.append('type', _this.uploadType)
    //       const res = await upload(forms)
    //       if(res.code == 200){
    //         insertImgFn(res.data)
    //       }
    //     }catch(e){
    //       console.warn('富文本图片上传失败')
    //     }
    //   })
    //   // let imgUrl = 'https://img01.yzcdn.cn/vant/apple-1.jpg'
    //   // setTimeout(() => {
    //   //   insertImgFn(imgUrl)
    //   // })
    // }
    this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {

      for(let i=0; i < resultFiles.length; i++){
        const isLt5M = resultFiles[i].size / 1024 / 1024 < _this.maxSize;
        if(!isLt5M) return _this.$message.error('上传图片大小不能超过 5MB!')
      }

      const uploadAction = (item) => {
        return new Promise(async(resolve, reject) => {
          try{
            const form = new FormData()
            form.append('file', item)
            form.append('type', _this.uploadType)
            const res = await upload(form)
            if(res.code == 200){
              resolve(res.data)
            }else{
              reject(res)
            }
          }catch(e){
            reject(e)
          }
        })
      }
      Promise.all( resultFiles.map(item => uploadAction(item)) ).then(srcs => {
        srcs.forEach(url => {
          insertImgFn(url)
        })
      }).catch(e => {
        console.warn('富文本图片上传失败')
      })
    }
   
    // 创建编辑器
    this.editor.create()
    //disabled 设置
    // if(this.disabled){
    //   this.editor.disable()  // 禁用;  启用: this.editor.enable()
    // }
    
    // console.error('editor.getAllMenuKeys():', this.editor.getAllMenuKeys())
  },
  methods: {
    setHtml(html = ''){
      this.editor.txt.html(html)
    }
  },
  beforeDestroy(){
    if(this.editor == null) return
    this.editor.destroy()
    this.editor = null
  },
}
</script>

<style>

</style>