<template>
<div style="width:650px">
  <el-form ref="form" :model="formData" label-width="130px" size="mini" label-position="right">
    <el-form-item v-for="(item,index) of columnComputedList" :key="index" :prop="item.key" :label="item.label" verify>
      
      <el-input 
        v-if="item.type == 'text'"
        v-model="formData[item.key]" 
        placeholder="请输入运费" 
        :maxlength="20"
      ></el-input>
      
      <el-input 
        v-if="item.type == 'textarea'"
        v-model="formData[item.key]" 
        type="textarea" 
        :rows="5" 
        placeholder="请输入描述" 
        :maxlength="50"
      ></el-input>

      <el-select v-if="item.type == 'select'" 
        size="mini" id="select" 
        v-model="formData[item.key]" 
        placeholder="请选择" 
        clearable
      >
        <el-option
          v-for="elem in item.options"
          :key="elem.value"
          :label="elem.label"
          :value="elem.value">
        </el-option>
      </el-select>

      <el-radio-group 
        v-if="item.type == 'radio'"  
        v-model="formData[item.key]"
      >
        <el-radio v-for="(elem,idx) in item.options" :key="idx" :label="elem.value"> {{elem.label}} </el-radio>
      </el-radio-group>

      <el-checkbox-group v-if="item.type == 'checkbox'" v-model="formData[item.key]">
        <el-checkbox v-for="elem in item.options" :label="elem.label" :key="elem.value">{{elem.label}}</el-checkbox>
      </el-checkbox-group>
      
      <!-- <slot v-if="item.slot" :name="item.slot"></slot> -->
    </el-form-item>

    <slot name="custom"></slot>
  </el-form>
  <gl-flex>
    <el-button type="primary">确认</el-button>
    <el-button >关闭</el-button>
  </gl-flex>
</div>
</template>

<script>
export default {
  name: 'GlDynamicForm',
  props: {
    config: {},
    columns: {},
  },
  data(){
    return{
      optionList: [
        {label: '选项1', value: '1'},
        {label: '选项2', value: '2'},
      ],
      dynamicForm: {
        dynamics: [
          {
            type: 'text',
            key: 'userName',
            label: '用户名',
            value: '',
            required: true,
            maxLength: 50,
          },
          {
            type: 'textarea',
            key: 'desc',
            label: '描述',
            value: '',
            required: true,
            maxLength: 50,
            rows: 5,
          },
          {
            type: 'select',
            key: 'type',
            label: '用户类型',
            value: '1',
            required: true,
            options: [
              {label: '管理员', value: 'admin'},
              {label: '用户', value: 'user'},
              {label: '测试', value: 'test'},
            ],
          },
          {
            type: 'radio',
            key: 'gender',
            label: '性别',
            value: 1,
            required: true,
            options: [
              {label: '男', value: 1},
              {label: '女', value: 0},
              {label: '保密', value: 2},
            ],
          },
          {
            type: 'checkbox',
            key: 'checked',
            label: '兴趣爱好',
            value: ['1'],
            required: true,
            options: [
              {label: '写代码', value: '1'},
              {label: '追剧', value: '2'},
              {label: '看电影', value: '3'},
            ]
          },
          {
            slot: 'custom',
          },
        ],
      },
      columnComputedList: [
        {
          type: 'text',
          key: 'userName',
          label: '用户名',
          value: '',
          required: true,
          maxLength: 50,
        },
        {
          type: 'textarea',
          key: 'desc',
          label: '描述',
          value: '',
          required: true,
          maxLength: 50,
          rows: 5,
        },
        {
          type: 'select',
          key: 'type',
          label: '用户类型',
          value: '1',
          required: true,
          options: [
            {label: '管理员', value: 'admin'},
            {label: '用户', value: 'user'},
            {label: '测试', value: 'test'},
          ],
        },
        {
          type: 'radio',
          key: 'gender',
          label: '性别',
          value: 1,
          required: true,
          options: [
            {label: '男', value: 1},
            {label: '女', value: 0},
            {label: '保密', value: 2},
          ],
        },
        {
          type: 'checkbox',
          key: 'checked',
          label: '兴趣爱好',
          value: ['1'],
          required: true,
          options: [
            {label: '写代码', value: '1'},
            {label: '追剧', value: '2'},
            {label: '看电影', value: '3'},
          ]
        },
        {
          slot: 'custom',
        },
      ],
      formData: {
        checked: ['1','2'],
      },
      // formData: this.$form.createForm(this)
    }
  },
  created(){

  },
  methods: {
    initFormData(list){
      list.forEach(item => {
        this.formData[item.key] = item.value
      })
    },
  },
}
</script>

<style>

</style>