<template>
  <div class="home">
    <div class="header-nav">
      <el-button type="primary" size="small" @click="handleToLogin">登陆</el-button>
      <el-button type="primary" size="small" @click="handleToMenu"> 菜单页 </el-button>
    </div>
    <div class="old-content">
      <img alt="Vue logo" src="../assets/logo.png">
      <HelloWorld msg="Welcome to Your Vue.js App"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  methods: {
    handleToLogin(){
      this.$router.push({path: '/login', replace: true})
    },
    handleToMenu(){
      this.$router.push({path: '/main', query: {id: '666'}})
    }
  }
}
</script>

<style lang="less" scoped>
// .header-nav{}
.old-content{
  text-align: center;
}
</style>
