import Cookies from 'js-cookie'
// import {enCode, deCode} from '@/utils/base64'

// 编码
const enCode = (data) => {
  let str = JSON.stringify(data)
  let base64 = window.btoa(str)
  return base64
}
// 解码
const deCode = (base64) => {
  let code = window.atob(base64)
  let data = JSON.parse(code)
  return data
}



const tokenKey = 'access_token_key'
const accountKey = 'account_key'
const rememberPasswordKey = 'remember_password_key'

const expires_time = 30

const setToken = (token) => {
  Cookies.set(tokenKey, token, { expires: expires_time, path: '' })
}
const token = () =>  Cookies.get(tokenKey)
const removeToken = () => {
  Cookies.remove(tokenKey, { path: '' })
}


const setAccountInfo = (value) => {
  // const accountInfo = enCode(value)
  // Cookies.set(accountKey, accountInfo, { expires: expires_time, path: '' })
  
  const accountInfo = JSON.stringify(value) 
  Cookies.set(accountKey, accountInfo, { expires: expires_time, path: '' })
}
const getAccountInfo = () => {
  // const accountInfo = Cookies.get(accountKey)
  // return deCode(accountInfo)
  
  return JSON.parse(Cookies.get(accountKey)) 
}


const setIsRemember = (value) => {
  if(value){
    Cookies.set(rememberPasswordKey, value, { expires: expires_time, path: '' })
  }else{
    clearRemember()
  }
}
const clearRemember = () => {
  Cookies.remove(accountKey, { path: '' })
  Cookies.remove(rememberPasswordKey, { path: '' })
}

const getIsRemember = () => {
  let isRemember = Cookies.get(rememberPasswordKey)
  return Boolean(isRemember ) || false
}




export default {
  setToken,
  removeToken,
  token,
  setAccountInfo,
  getAccountInfo,
  setIsRemember,
  getIsRemember,
}