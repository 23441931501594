

import coolRouters from './cool-routers'
import backendallRouters from './backendall-routers'
import merchantRouters from './merchant-routers'

const DynamicMainMap = {
  ...coolRouters,     // 酷喜路由
  ...backendallRouters,  // 中后台路由
  ...merchantRouters,   // 厂家后台
}


export default DynamicMainMap