import Vue from 'vue'

import {
    Button,
    Menu,
    Submenu,
    MenuItem,
    DatePicker,
    Input,
    Card,
    Form,
    FormItem,
    Timeline,
    TimelineItem,
    Select,
    Option,
    Table,
    TableColumn,
    Pagination,
    Popover,
    Checkbox,
    CheckboxGroup,
    ButtonGroup,
    Message,
    MessageBox,
    Radio,
    RadioGroup,
    Upload,
    Dialog,
    Loading,
    Scrollbar,
    TimePicker,
    Image,
    InputNumber,
    Autocomplete,
    Tabs,
    TabPane,
    RadioButton,
    Row,
    Col,
    Badge,
    Switch,
    Cascader,
    Tree,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Avatar,
    Rate,
    Carousel,
    CarouselItem,
    Tooltip,
    Link,
    Divider,
} from 'element-ui'

import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
Vue.component(CollapseTransition.name, CollapseTransition)

Vue.use(Button)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(DatePicker)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Popover)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(ButtonGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Upload)
Vue.use(Dialog)
Vue.use(Loading)
Vue.use(Scrollbar)
Vue.use(TimePicker)
Vue.use(Image)
Vue.use(InputNumber)
Vue.use(Autocomplete)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(RadioButton)
Vue.use(Row)
Vue.use(Col)
Vue.use(Badge)
Vue.use(Switch)
Vue.use(Cascader)
Vue.use(Tree)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Avatar)
Vue.use(Rate)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Tooltip)
Vue.use(Card)
Vue.use(Link)
Vue.use(Divider)


Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt

// 表单校验
// https://github.com/aweiu/element-ui-verify#fieldchange
import elementUIVerify from 'element-ui-verify'

const errorMessageTemplate = {
    empty: '{alias}不能为空',
    length: '请输入{length}位字符',
    minLength: '输入内容至少{minLength}位',
    number: '请输入数字',
    int: '请输入整数',
    lt: '输入数字应小于{lt}',
    lte: '输入数字不能大于{lte}',
    gt: '输入数字应大于{gt}',
    gte: '输入数字不能小于{gte}',
    maxDecimalLength: '该输入项最多接受{maxDecimalLength}位小数',
    phone: '请输入正确的手机号',
    email: '请输入正确的邮箱',
    verifyCode: '请输入正确的验证码'
}

Vue.use(elementUIVerify, {
    errorMessageTemplate: errorMessageTemplate,
    // fieldChange: 'clear',
})
Vue.use(elementUIVerify)
