

export default {
  '/main/cool/index': {
    path: '/main/cool/index',
    component: () => import('@/views/coolLike/home/home.vue'),
    meta: { title: '首页' }
  },
  //订单管理菜单
  // '/main/cool/order/list': {
  //   path: '/main/cool/order/list',
  //   component: () => import('@/views/coolLike/order/list/index.vue'),
  //   redirect: '/main/cool/order/list/index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/coolLike/order/list/list.vue'),
  //       meta: { title: '订单列表' }
  //     },
  //     {
  //       path: 'detail',
  //       component: () => import('@/views/coolLike/order/list/detail.vue'),
  //       meta: { title: '订单详情' }
  //     },
  //     {
  //       path: 'refund',
  //       component: () => import('@/views/coolLike/order/list/refund.vue'),
  //       meta: { title: '退货管理' }
  //     }
  //   ]
  // },


  '/main/cool/order/list/orderList': {
    path: '/main/cool/order/list/orderList',
    component: () => import('@/views/coolLike/order/list/index.vue'),
    redirect: '/main/cool/order/list/orderList/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/coolLike/order/list/list.vue'),
        meta: { title: '订单列表' }
      },
      {
        path: 'detail',
        component: () => import('@/views/coolLike/order/list/detail.vue'),
        meta: { title: '订单详情' }
      },
      {
        path: 'refund',
        component: () => import('@/views/coolLike/order/list/refund.vue'),
        meta: { title: '退货管理' }
      }
    ]
  },
  '/main/cool/order/list/groupPlatform': {
    path: '/main/cool/order/list/groupPlatform',
    component: () => import('@/views/coolLike/order/list/index.vue'),
    redirect: '/main/cool/order/list/groupPlatform/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/coolLike/order/list/groupPlatform.vue'),
        meta: { title: '团购管理' }
      },
      {
        path: 'groupPlatformInfo',
        component: () => import('@/views/coolLike/order/list/groupPlatformInfo.vue'),
        meta: { title: '团购详情' }
      }
    ]
  },


  //账号权限管理
  '/main/cool/account/management': {
    path: '/main/cool/account/management',
    component: () => import('@/views/coolLike/account/management/list.vue'),
    meta: { title: '账号管理' }
  },
  '/main/cool/account/roles': {
    path: '/main/cool/account/roles',
    component: () => import('@/views/coolLike/account/roles/list.vue'),
    meta: { title: '角色权限' }
  },

  //订货管理菜单
  '/main/cool/goodsManagement/goods': {
    path: '/main/cool/goodsManagement/goods',
    component: () => import('@/views/coolLike/goodsManagement/goods/index.vue'),
    meta: { title: '商品' },
    redirect: '/main/cool/goodsManagement/goods/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/coolLike/goodsManagement/goods/newList.vue'),
        meta: { title: '商品' }
      },
      {
        path: 'details',
        component: () => import('@/views/coolLike/goodsManagement/goods/details.vue'),
        meta: { title: '商品详情' }
      },
      {
        path: 'createOrder',
        component: () => import('@/views/coolLike/goodsManagement/goods/createOrder.vue'),
        meta: { title: '创建订单' }
      }
    ]
  },
  '/main/cool/goodsManagement/order': {
    path: '/main/cool/goodsManagement/order',
    component: () => import('@/views/coolLike/goodsManagement/order/index.vue'),
    redirect: '/main/cool/goodsManagement/order/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/coolLike/goodsManagement/order/list.vue'),
        meta: { title: '订货订单' },
      },
      {
        path: 'detail',
        component: () => import('@/views/coolLike/goodsManagement/order/detail.vue'),
        meta: { title: '订货订单详情' },
      },
    ],
  },

  //新增抽奖模块lottery一级路由抽奖管理，二级菜单lotterySetting抽奖设置，lotteryRecord抽奖记录
  '/main/cool/lottery/lotterySetting': {
    path: '/main/cool/lottery/lotterySetting',
    component: () => import('@/views/coolLike/lottery/lotterySetting/index.vue'),
    redirect: '/main/cool/lottery/lotterySetting/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/coolLike/lottery/lotterySetting/list.vue'),
        meta: { title: '抽奖设置' }
      },
      {
        path: 'detail',
        component: () => import('@/views/coolLike/lottery/lotterySetting/detail.vue'),
        meta: { title: '抽奖设置详情' }
      }
    ]
  },
  '/main/cool/lottery/lotteryRecord': {
    path: '/main/cool/lottery/lotteryRecord',
    component: () => import('@/views/coolLike/lottery/lotteryRecord/index.vue'),
    redirect: '/main/cool/lottery/lotteryRecord/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/coolLike/lottery/lotteryRecord/list.vue'),
        meta: { title: '抽奖记录' }
      },
      {
        path: 'detail',
        component: () => import('@/views/coolLike/lottery/lotteryRecord/detail.vue'),
        meta: { title: '抽奖记录详情' }
      }
    ]
  },


  //财务管理菜单
  // '/main/cool/finance/orderList': {
  //   path: '/main/cool/finance/orderList',
  //   component: () => import('@/views/coolLike/finance/orderList/list.vue'),
  //   meta: {title: '结算列表'}
  // },
  '/main/cool/finance/orderList': {
    path: '/main/cool/finance/orderList',
    component: () => import('@/views/coolLike/finance/orderList/index.vue'),
    redirect: '/main/cool/finance/orderList/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/coolLike/finance/orderList/list.vue'),
        meta: { title: '结算列表' }
      },
      {
        path: 'detail',
        component: () => import('@/views/coolLike/finance/orderList/detail.vue'),
        meta: { title: '详情' }
      }
    ],
    meta: { title: '结算列表' }
  },
  '/main/cool/finance/withdrawDeposit': {
    path: '/main/cool/finance/withdrawDeposit',
    component: () => import('@/views/coolLike/finance/withdrawDeposit/list.vue'),
    meta: { title: '结算提现' }
  },
  '/main/cool/userInfo': {
    path: '/main/cool/userInfo',
    component: () => import('@/views/coolLike/userInfo/list.vue'),
    meta: { title: '小喜管理' },
  },
  '/main/cool/bigLike': {
    path: '/main/cool/bigLike',
    component: () => import('@/views/coolLike/bigLike/list.vue'),
    meta: { title: '大喜管理' },
  },

}