
import Home from '../views/Home.vue'

const routers = [
  {
    path: '/',
    redirect: '/main',  // /login
    // name: 'Home',
    // component: Home,
    // // redirect: '/main'
  },
  {
    path: '/login',
    name: '登陆',
    component: () => import('@/views/const/login.vue')
  },
  {
    path: '/common',
    name: '通用组件',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/common/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/const/page-404.vue'),  // 默认给404页面
      },
      {
        path: 'update',
        component: () => import('@/views/const/updatePassword.vue'),
        meta: { title: '修改密码' }
      },
      {
        path: 'test',
        component: () => import('@/views/const/componentTest.vue'),
        meta: { title: '组件测试' }
      },
    ],
  },
  // {
  //     path: '/main',
  //     name: 'main',
  //     // component: () => import('@/views/main-view/index.vue'),
  //     component: () => import('@/views/layout/index.vue'),
  //     // redirect: '/main/index',
  //     children: [],
  // },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/const/page-404.vue')
  },
  // {
  //   path: '*',
  //   name: '404',
  //   component: () => import('@/views/const/page-404.vue')
  // }
]


export default routers