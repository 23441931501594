<template>
  <div class="gl-title">
    <div class="top">
      <div class="left">
        <div v-if="back.display" @click="back.click()">
          <i class="icon_svg">
            <svg t="1591348589720" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2845" width="18" height="18">
              <path d="M941.056 450.56H309.76l269.824-281.6-86.016-86.016L82.944 512l410.624 429.056 86.016-86.016-269.824-281.6h631.296V450.56z" fill="#006eff" p-id="2846"></path>
            </svg>
          </i>
        </div>
        <span>{{ title || $route.meta.title }}</span>
      </div>
      <div class="right" v-if="rightButtons">
        <!-- 按钮组 -->
        <div v-for="(rightButton, index) in rightButtons" :key="index">
          <el-button size="small" :type="rightButton.type" @click.native="rightButton.click()">
            {{ rightButton.name }}
          </el-button>
        </div>
      </div>
    </div>

    <div class="bottom-tab" v-if="tabPanes && tabPanes.length > 0">
      <div class="top-title-tabs">
        <a class="top-title-tabs__title" :class="{ active: curTabValue === item.name }" v-for="(item, index) in tabPanes" :key="index" @click.stop="onClickTab(item,index)">{{ item.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlTitle',
  props: {
    title: {
      type: String,
      default: null,
    },
    backIcon: {
      type: Boolean,
      default: false,
    },
    customBackFunction: {
      type: Function,
      default: null,
    },
    rightButtons: {
      type: Array,
      default: () => [
        // {
        //   name: '确定',
        //   type: 'primary',
        //   click: () => console.log('点击了确定按钮')
        // },
        // {
        //   name: '取消',
        //   click: () => console.log('点击了取消按钮')
        // }
      ]
    },
    curTab: {
      type: [String, Number],
      default: undefined,
    },
    tabPanes: {
      type: Array,
      default: () => []
    },
  },
  data(){
    return{
      back: {
        disabled: false,
        display: this.backIcon ? true : false,
        click: this.customBackFunction ? this.customBackFunction : this.goBack, // 判断是否传入自定义返回函数
      },
      curTabValue: undefined,
    }
  },
  methods: {
    setInitialTab(tabPanes) {
      if (this.curTab) return false;
      // 无初始化值，则初始化curTab
      if (!tabPanes || tabPanes.length === 0) return false;
      this.curTabValue = tabPanes[0].name;
      this.$emit("update:curTab", tabPanes[0].name);
    },
    onClickTab(tabItem,index) {
      this.curTabValue = tabItem.name;
      this.$emit("update:curTab", tabItem.name);
      this.$emit('tabClick', tabItem, index)
    },
    goBack() {
      window.history.back(-1);
    },
  },
  mounted(){
    this.setInitialTab(this.tabPanes);
  },
}
</script>

<style lang="less" scoped>
.gl-title{
  .top {
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    // width: calc(100vh - 65px);
    
    .left {
      text-align: left;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      display: flex;

      span{
        min-width: 200px;
      }

      .icon_svg {
        margin-right: 20px;
        display: inline-block;
        padding-top: 2px;
        vertical-align: middle;

        svg {
          fill: #006eff;
        }
      }
    }
    .right {
      margin: 0;
      display: flex;

      .el-button {
        margin-left: 10px;
        padding: 8px 22px;
        height: 30px;
      }
    }

    //按钮
    .el-button {
      border-radius: 0;
    }
    .el-button--default {
      color: #000;
      border: 1px solid #ddd;

      &:hover {
        color: #000;
        background: #f2f2f2;
        border-color: #ddd;
      }

      &:active {
        color: #006eff;
        background: #ffffff;
        border: 1px solid #006eff;
      }

      &:focus {
        color: #006eff;
        background: #ffffff;
        border: 1px solid #006eff;
      }
    }

    .el-button--text {
      padding: 2px 2px;
      color: #006eff;
      border: 0;

      &:hover {
        color: #006eff;
        background: #f2f2f2;
      }

      &:active {
        color: #006eff;
        background: #f2f2f2;
      }

      &:focus {
        color: #006eff;
        background: #f2f2f2;
      }
    }
    .el-button--primary {
      color: #fff;
      background-color: #006eff;
      border: 1px solid #006eff;

      &:focus,
      &:hover {
        color: #fff;
        background: #338bff;
        border-color: #338bff;
      }

      &:active {
        color: #fff;
        background: #0063e6;
        border-color: #0063e6;
      }
    }

  
  }

  .bottom-tab {
    background: #fff;
    padding: 0 20px;
    padding-top: 10px;
    .top-title-tabs {
      line-height: 1;

      &__title {
        display: inline-block;
        margin-right: 40px;
        height: 26px;
        font-size: 14px;
        color: #000;
        box-sizing: border-box;
        cursor: pointer;

        &:hover,
        &.active {
          font-weight: bold;
          border-bottom: 2px solid #0070f9;
        }
      }
    }
  }

}
</style>