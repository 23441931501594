<template>
  <div class="gl-list-search">
    <div v-if="label" class="label" :style="{width: labelWidth}"> {{label}} </div>
    <el-input v-model="keyword" size="mini"  :placeholder="placeholder" @keyup.enter.native="enterKey"></el-input>
  </div>
</template>

<script>
export default {
  name: 'GlListSearch',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请输入',
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: '80px'
    }
  },
  data(){
    return{
      keyword: ''
    }
  },
  watch: {
    value: {
      handler: function(newVal){
        this.keyword = newVal
      },
      deep: true,
      // immediate: true,
    },
    keyword: {
      handler: function(newVal){
        this.$emit('input', newVal)
        this.$emit('change', newVal)
      }
    },
  },
  methods: {
    enterKey(event) {
      const componentName = this.$options.name
      if (componentName == 'GlListSearch') {
        const code = event.keyCode
          ? event.keyCode
          : event.which
          ? event.which
          : event.charCode
        if (code == 13) {
          this.$emit('enter', this.keyword)
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.gl-list-search{
  display: flex;
  align-items: center;
  .label{
    width: 80px;
  }
}
</style>

