
const ls = window.localStorage

export const LStorage = {
  get(key){
    try{
      return JSON.parse(ls.getItem(key))
    }catch(e){
      return null
    }
  },
  set(key, val){
    ls.setItem(key, JSON.stringify(val))
  },
  clear(){
    ls.clear()
  },
  keys(){
    return ls.keys()
  },
  remove(key){
    ls.removeItem(key)
  }
}



const ss = window.sessionStorage

export const SStorage = {
  get(key){
    try{
      return JSON.parse(ss.getItem(key))
    }catch(e){
      return null
    }
  },
  set(key, val){
    ss.setItem(key, JSON.stringify(val))
  },
  remove(key){
    ss.removeItem(key)
  },
  clear(){
    ss.clear()
  }
}


export default {
  LStorage: LStorage,
  SStorage,
}