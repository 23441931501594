

function setGoodsSkuVOList(tableList){
  return tableList.map(item => {
    return {
      ...item,
      skuSize: item.skuSize,
      skuName: item.skuName,
      inventory: Number(item.inventory),
      retailPrice: Number(item.retailPrice),
      costPrice: Number(item.costPrice),
      skuPicture: item.skuPicture,
      presalePrice: item.presalePrice,
      batchPrice: item.batchPrice,
    }
  })
}


const state = {
  isPresale: false, //是否预售
  isBatch: false, // 是否批发
  //尺码
  skuSizes: [], //['均码'],
  checkList: [],
  //规格
  skuNames: [],

  tableList: [],
  tableData: [],
  tableForm: {},
  goodsSkuVOList: [
    // {
    //   skuSize: null, //尺寸
    //   skuName: null,  //规格名称
    //   inventory: null,  // 库存
    //   retailPrice: null, //零售单价
    //   costPrice: null,  // 出厂价
    //   batchPrice: null, // 批发价
    //   skuPicture: null, // 商品规格图片
    // },
  ],
  goodsImage: {},  //保存上传规格图片
}

const getters = {}

const mutations = {
  addSkuSizes(state, skuSize){
    state.checkList.push(skuSize)
    state.skuSizes.push(skuSize)
    state.goodsImage[skuSize] = {}
    state.tableData = state.checkList.map(item => {
      return{
        skuSize: item,
        skuNames: [...state.skuNames]
      }
    })
    state.tableList = handleSizes(state.tableList, state.checkList, state.skuNames, state.tableForm)
  },
  setSkuSizes(state, checkList){
    state.checkList = checkList
    for(let key in state.goodsImage){
      if(!state.checkList.includes(key)){
        delete state.goodsImage[key]
      }else{
        state.goodsImage[key] = {}
      }
    }
    state.tableData = state.checkList.map(item => {
      return{
        skuSize: item,
        skuNames: [...state.skuNames]
      }
    })
    state.tableList = handleTableList(state.tableData, state.tableForm, state.goodsImage)
  },
  removeSkuSizes(state, index){
    const value = state.skuSizes[index]
    const idx = state.checkList.indexOf(value)
    if(idx != -1){
      state.checkList.splice(idx, 1)
      state.tableList = state.tableList.filter(item => item.skuSize != value)
    }
    state.skuSizes.splice(index,1)
    delete state.goodsImage[value]  //删除保存的 图片
  },
  addSkuNames(state, type){
    state.skuNames.push(type)

    state.checkList.forEach(item => {
      if(item){
        state.goodsImage[item][type] = ''
      }
      // state.goodsImage[item ? item: ''][type] = ''
    })

    if(state.checkList.length == 0){
      // state.tableData.push({})
      state.checkList.push('')
      state.skuSizes.push('')
      state.tableData[0] = {skuNames: [...state.skuNames], skuSize: ""}
    }else{
      state.tableData = state.tableData.map(item => {
        return {
          ...item,
          skuNames: [...state.skuNames]
        }
      })
    }
    
    // state.tableData = state.tableData.map(item => {
    //   return {
    //     ...item,
    //     skuNames: [...state.skuNames]
    //   }
    // })
    
    state.tableList = handleTableList(state.tableData, state.tableForm, state.goodsImage)
    
  },
  removeSkuNames(state, index){
    const value = state.skuNames[index]
    state.skuNames.splice(index, 1)
    state.tableList = state.tableList.filter(item => {
      return item.skuName != value
    })
    //删除保存的图片
    state.checkList.forEach(item => {
      delete state.goodsImage[item][value]
    })
  },
  setTableList(state, formData){
    state.tableList = state.tableList.map(item => {
      return {
        ...item,
        ...state.tableForm,
      }
    })
    state.goodsSkuVOList = setGoodsSkuVOList(state.tableList)
  },
  setGoodsSkuVOList(state, index){
    state.goodsSkuVOList = setGoodsSkuVOList(state.tableList)
  },
  clear(state){
    state.checkList = []
    state.skuSizes = []
    state.skuNames = []
    state.tableList = []
    state.tableData = []
    state.tableForm = {}
    state.goodsSkuVOList = []
    state.goodsImage = {}
  },
}

function handleSizes(tableList, checkList, skuNames, formData){
  let result = tableList
  if(!skuNames.length){
    result.push({
      ...formData,
      skuSize: checkList[checkList.length -1],
      skuName: '',
    })
  }else{
    skuNames.forEach((item,index) => {
      result.push({
        ...formData,
        skuSize: checkList[checkList.length -1],
        skuName: item,
      })
    })
  }
  return result
}

function handleTableList(tableData, tableForm, goodsImage){
  let result = []
  tableData.forEach(item => {
    item.skuNames.forEach(elem => {
      result.push({
        ...tableForm,
        skuSize: item.skuSize,
        skuName: elem,
        skuPicture: goodsImage[item.skuSize] ? goodsImage[item.skuSize][elem] || '' : ''
      })
    })
  })
  return result
}



const actions = {}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}