/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-03-13 09:27:41
 * @LastEditors: pj
 * @LastEditTime: 2023-03-13 09:52:05
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'


import constRouters from './const-routers'
import DynamicMainMap from './dynamic-main'


Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}



const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  isAddRouter: false,
  routes: constRouters,
})

//装配路由
const genRoute = (subMenu, childRouters) => {
  return new Promise((resolve, reject) => {
    let newRouters = []
    for (let i = 0; i < subMenu.length; i++) {
      let path = subMenu[i].path
      if (childRouters[path]) {
        const meta = childRouters[path].meta
        const buttonList = subMenu[i].buttonList
        if (childRouters[path].redirect) {  //处理路由重定向 meta参数buttonList配置
          childRouters[path].children[0].meta = { ...childRouters[path].children[0].meta, buttonList }
          // const index = childRouters[path].children.findIndex(item => item.path == 'index') || 0  // 动态
          // childRouters[path].children[index].meta = {...childRouters[path].children[index].meta, buttonList}
        }
        childRouters[path].meta = { ...meta, buttonList }
        newRouters.push(childRouters[path]);
      }
    }
    newRouters.push({
      path: '/main/update',
      component: () => import('@/views/const/updatePassword.vue'),
    })
    newRouters.push({
      path: '/main/*',
      component: () => import('@/views/const/page-404.vue'),
    })
    router.addRoute({
      path: '/main',
      component: () => import('@/views/layout/index.vue'),
      redirect: newRouters[0].path,
      children: newRouters
    })
    resolve(true)
  })
}

// const hasToken = getToken()


router.beforeEach((to, from, next) => {
  // console.log("router-beforeEach:",to)
  // next()
  let token = store.getters['user/accessToken']
  if (!token) {
    if (to.path === '/login') {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }

  } else {
    if (!router.options.isAddRouter) {
      store.dispatch({ type: 'user/loadMenuList' }).then(async menuList => {
        router.options.isAddRouter = await genRoute(menuList, DynamicMainMap)
        return next({ ...to, replace: true, })
      })

    } else {
      if (to.path == '/login') {
        store.dispatch('user/logout').then(res => location.reload())
        router.options.isAddRouter = false
      }
      next()

    }

  }

})



export default router
