<template>
  <div class="gl-card" style="min-width: 800px;">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GlCard',
}
</script>

<style lang="less" scoped>
.gl-card {
    margin: 18px;  //18px 18px 40px;
    padding: 10px 10px 24px;
    border: 1px solid #ebeef5;
    background-color: #fff;
    overflow: hidden;
    color: #303133;
    border-radius: 4px;
    // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
</style>

