import request from '@/plugins/request'

import menuList from './menuList'



//登陆
export function login (data = {}) {

  return request({
    url: `/api/user/login/login`,
    method: 'POST',
    data: data,
  })

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let data = {
        userName: 'evan',
        userId: '1',
        token: '888888888888888888'
      }
      resolve({
        msg: '操作成功',
        code: 200,
        data: data,
      })
    }, 1000)
  })
}

//获取用户信息
export function getUserInfo (params = {}) {

}

//获取菜单列表
export function getMenuList () {
  return request({
    url: `/api/user/menu/getMenu`,
    method: 'POST',
    data: {}
  })

  // .then(res => {
  //   res.data.push({})
  //   return res
  // })

  // return new Promise((resolve,reject) => {
  //   setTimeout(() => {
  //     console.error('调用菜单接口')
  //     let result = {
  //       code: 0,
  //       data: menuList,
  //       msg: '操作成功'
  //     }
  //     resolve(result)
  //   },500)
  // })
}


//退出登陆
export function logout () {
  return request({
    url: '/api/admin/login/logout',
    method: 'post',
    data: {},
    shwoloading: true,
  })
}


//图片上传
export function upload (data) {
  return request({
    url: '/api/file/file/uploadImage',
    method: 'POST',
    data,
  })
}

//获取短信验证码
export function getCode (params = {}) {
  return request({
    url: '/api/user/login/sendSms',
    method: 'GET',
    params,
  })
}
//修改密码
export function updatePwd (data = {}) {
  return request({
    url: 'api/user/account/changePassword',
    method: 'POST',
    data,
  })
}


export default {
  login,
  getUserInfo,
  getMenuList,
  getCode,
  updatePwd,
}

