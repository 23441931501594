import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './plugins/element.js' // element-ui
import 'element-ui/lib/theme-chalk/index.css'
// import './style/el-theme/theme/index.css' // 自定义el主题

import './style/font_icons/iconfont.css' // 侧栏icon

import './style/global.less'
import './style/reset-el.less'
// import './style/global.scss'

// import './plugins/auto-update' // 服务更新后 检查刷新页面

import "default-passive-events" //解决刷新页面路由警告问题(不影响正常开发)

import Cookies from './plugins/cookies.js' // 页面缓存
Vue.prototype.$cookies = Cookies  

import Dayjs from 'dayjs' // 时间格式化
Vue.prototype.$dayjs = Dayjs

//添加全局指令
import directive from '@/directive/index'
Vue.use(directive)

import Chat from 'jwchat';   // 客服聊天插件
Vue.use(Chat)



//**公用组件 */
import GlTitle from '@/components/global/gl-title.vue'
Vue.component('GlTitle', GlTitle)
import GlPageHeader from '@/components/global/gl-page-header.vue'
Vue.component('GlPageHeader', GlPageHeader)
import GlCard from '@/components/global/gl-card.vue'
Vue.component('GlCard', GlCard)
import GlPagination from '@/components/global/gl-pagination.vue'
Vue.component('GlPagination', GlPagination)
import GlListSearch from '@/components/global/gl-list-search.vue'
Vue.component('GlListSearch', GlListSearch)
import GlOption from '@/components/global/gl-option.vue'
Vue.component('GlOption', GlOption)
import GlDatePicker from '@/components/global/gl-date-picker.vue'
Vue.component('GlDatePicker', GlDatePicker)
import GlFlex from '@/components/global/gl-flex.vue'
Vue.component('GlFlex', GlFlex)
import GlWrap from '@/components/global/gl-wrap.vue'
Vue.component('GlWrap', GlWrap)
import GlUploadImages from '@/components/global/gl-upload-images.vue'
Vue.component('GlUploadImages', GlUploadImages)
// import glUploadImage from '@/components/global/gl-upload-image.vue'
// Vue.component('glUploadImage', glUploadImage)
import glDragUpload from '@/components/global/gl-drag-upload.vue'
Vue.component('glDragUpload', glDragUpload)
import GlEditor from '@/components/global/gl-editor.vue'
Vue.component('GlEditor', GlEditor)
import GlDynamicForm from '@/components/global/gl-dynamic-form.vue'
Vue.component('GlDynamicForm', GlDynamicForm)



Vue.config.productionTip = false

new Vue({
  directive,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
