<template>
  <div class="gl-option">
    <span v-if="label" class="option-label"> {{label}} </span>
    <el-select size="mini" id="select" v-model="selectedValue" :placeholder="placeholder" clearable @change="change">
      <el-option
        v-for="item in list"
        :key="item.value"
        :label="item.name"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'GlOption',
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    list: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: 'mini'
    },
    label: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      selectedValue: ''
    }
  },
  watch: {
    value: {
      handler: function(newVal){
        this.selectedValue = newVal
      },
      immediate: true,
    }
  },
  methods: {
    change(value){
      this.$emit('input', value)
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
// .gl-option{
//   // display: flex;
//   // align-items: center; 
//   .option-label{
//     // display: inline-block;
//   }
// }
</style>