import { showFullScreenLoading, tryHideFullScreenLoading } from './el-loading'
import { Message, MessageBox } from 'element-ui'

import axios from 'axios'
import store from '@/store'
import router from '../router'

const ERROR_CODE = [201, 202]

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, //  config baseApi
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000, // request timeout
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',  //上传图片: 'content-type': 'multipart/form-data',
    },
})

// 节流
var canRun = true
const _showErrorTip = function(tip) {
    if (!canRun) {
        return
    }
    canRun = false
    setTimeout(function() {
        Message({
            message: tip,
            type: 'error',
            duration: 5 * 1000
        })
        canRun = true
    }, 1000)
}

// request拦截器 request interceptor
service.interceptors.request.use(
    config => {
        // 不传递默认开启loading
        if (config.shwoloading) {
            showFullScreenLoading()
        }
        if (store.getters['user/accessToken']) {
            config.headers['X-Requested-Token'] = store.getters['user/accessToken']
            // config.headers['X-Feign'] = store.getters['user/accessToken']
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
// respone拦截器
service.interceptors.response.use(
    response => {
        // console.log('response:', response)
        // 对响应数据做点什么
        if (response.config.showLoading) {
            tryHideFullScreenLoading()
        }

        const res = response.data
        if(res.code === 201 || res.code === 202){
            // store.dispatch('user/logout').then(res => location.reload())
            _showErrorTip(res.msg || JSON.stringify(res))
             router.push('/login')
            return Promise.reject(res)

        }else if(typeof res === 'object' && res.code && res.code !== 200){
            _showErrorTip(res.msg || JSON.stringify(res))
            return Promise.reject(res)
        }
        
        return response.data

    },
    error => {
        // 对响应错误做点什么
        tryHideFullScreenLoading()
        console.error('response-err:' + error) // for debug
        _showErrorTip(error.message || JSON.stringify(error))
        return Promise.reject(error)
    }
)

export default service
