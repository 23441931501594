

//菜单扁平化-递归
// export function flatten(arr) {
//   var result = []
//   for(let i = 0, len = arr.length; i < len; i++){
//     if (arr[i].children){
//       result = result.concat(flatten(arr[i].children))
//     }
//     if(arr[i].islast) result.push(arr[i].path)
//   }
//   return result
// }
export function flatten(arr) {
  var result = []
  for(let i = 0, len = arr.length; i < len; i++){
    if (arr[i].children && arr[i].children.length > 0){
      result = result.concat(flatten(arr[i].children))
    }
    else{
      result.push({
        path: arr[i].path,
        buttonList: arr[i].buttonList || null
      })
    }
  }
  return result
}

//把对象转换为url ? 后面的参数: GET请求参数 obj转string
export function queryString(query = {}){
  const keys = Object.keys(query)
  let str = ''
  keys.forEach((key, index) => {
      if (query[key] !== undefined && query[key] !== null) {
          str += `${key}=${decodeURIComponent(query[key])}`
      }
      if (index < keys.length - 1) {
          str += '&'
      }
  })
  return str
}



//防抖
export function debounce(fn, delay = 500){
  let timer
  return function(...args) {
    if(timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this , args)
    }, delay)
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce_old(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

// 节流
export function throttle(fn , delay = 500) {
  let last = 0  //上传触发时间
  return function (...args){
    const now = Date.now()
    if(now - last > delay){
      last = now
      fn.apply(this, args)
    }
  }
}

//设置元素的高度
export const setContentHeight = (that,ele,height) => {
  that.$nextTick(() => {
    ele.style.height =   (document.body.clientHeight - height)+'px'
  })
}


export default {
  flatten,
  queryString,
  setContentHeight,
}