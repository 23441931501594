<template>
  <div class="gl-date-picker">
    <div v-if="label" :style="{width: labelWidth}"> {{label}} </div>
    <!-- style="margin-left: 12px" -->
    <el-date-picker
      v-model="selectValue"
      size="mini"
      type="daterange"
      clearable
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :value-format="valueFormat"
      @change="handleChange"
      :disabled="disabled"
    ></el-date-picker>
    <!-- :picker-options="pickerOptions" -->
  </div>
</template>

<script>
export default {
  name: 'GlDatePicker',
  props: {
    value: {
      // type: Array,
      // default: () => ['2022-01-20','2022-11-02'],
      type: [String, Array],
      default: '',
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd HH:mm:ss', // yyyy-MM-dd HH:mm:ss
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: '80px'
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ifInitRest: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: String,
      default: null
    },
    endTime: {
      type: String,
      default: null,
    },
  },
  data(){
    return{
      selectValue: this.value || [],
    }
  },
  watch: {
    value: {
      handler: function(newVal){
        if(newVal){
          this.selectValue = newVal
        }else{
          this.selectValue = []
        }
      },
      deep: true,
    },
    startTime: {
      handler: function(newVal){
        if(!newVal){
          this.selectValue = []
        }
      }
    },
  },
  created(){
    if(this.ifInitRest){
      Object.assign(this.$data, this.$options.data())
    }
  },
  methods: {
    handleChange(val){
      let dateTime = val
      if(!val) dateTime = ['',''] 
      this.$emit('update:startTime', dateTime[0])
      this.$emit('update:endTime', dateTime[1])
      this.$emit('input', val ? dateTime : [])
      this.$emit('change', dateTime)
    },
    reset(){
      this.selectValue = []
      this.$emit('input', [])
      this.$emit('update:startTime', '')
      this.$emit('update:endTime', '')
    },
  },
}
</script>

<style lang="scss" scoped>
.gl-date-picker{
  display: flex;
  align-items: center;
}
</style>