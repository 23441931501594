import Vue from 'vue'
import Vuex from 'vuex'


import user from './modules/user'
import goods from './modules/goods'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false, // 设置v-loading加载效果
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    goods,
  }
})
