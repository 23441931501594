import router from '@/router'
// import store from '@/store'

//授权按钮指令
function checkAuth(el, binding){
  const {value} = binding
  const buttons = router.currentRoute.meta.buttonList || []
  if(value && buttons.length){
    const checked = buttons.some(item => item.id == value)
    !checked && el.remove()
  }
  
  // 方法二
  // const buttonIdList = store.state.user.buttonList
  // if(value && buttonIdList.length) {
  //   const checked = buttonIdList.some(item => item.id == value)
  //   !checked && el.remove()
  // }
}

export default {
  inserted(el, binding){
    checkAuth(el, binding)
  },
  // update(el, binding){
  //   el.style.color = 'red'   
  //   checkAuth(el, binding)
  // },
}